
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { Field } from "vee-validate";
import { VueCookieNext } from 'vue-cookie-next';
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "kt-drawer-course-updateDrawer",
  components: { Field },
  props: {
    data: { type: Object },
  },
  data() {
    return {
      percentageInfo: "",
      courseTermCount: 0,
      tranche: [],
      association: [],
      stipenchecked: "no",
      managementchecked: "no",
      employmentchecked: "no",
      reassesmentchecked: "no",
      totchecked: "no",
      assesmentchecked: "no",
      certificationchecked: "no",
      instituteTypes: [],
         empDistricts: [] as any,
      subDistricts: [] as any,
      companies: [] as any,
      nidData: {},
      loading: false,
      showTrainerInfo: true,
      showNidInfo: true,
      companyData: false,
      sendImages: [] as any,
      Types: [] as any,
      certification_authority: "",
      skill_level_id: '' as any,
      contactList: [],
      courseList: [],
      categoryList: [],
      sectorList: [],
      courseAlias: [],
      skillLevelList: [],
      test: [],
      load: false,
      update_status: 0,
       address_details:
        {
          type: null,
          address: '',
          district: '',
          sub_district: '',
          land_mark: '',
          remarks: '',
          contact_person: '',
          images: [],
        }as any,
      formData: {
        id: "" as any,
        entity_id: "",
        tranche: "",
        entity_tranche_contract_id: "",
        course_type: "",
        course_category: "",
        course_sector: "",
        unit_cost_total: "",
        x_course_name_id: "",
        code: "",
        course_name: "",

        total_target_batches: "",
        trainee_per_batch: "",
        min_day_per_month: "",
        total_course_cost: 0,
        total_target_trainee: "",
        min_age: "",
        max_age: "",
        age_grace_in_month: "",
        course_objective: "",
        entry_qualification: "",
        // month: "",
        // hour: "",
        // day: "",
        // session: "",
        // term: "",

        isStipendCourse: 0,
        isManagementCourse: 0,
        isEmploymentEligible: 0,
        isReassessmentEnabled: 0,
        isTotCourse: 0,
     

        // course_config_info: {
        //   id: "",
        //   entity_course_id: "",
        //   e_grace: "",
        //   replacement_allowed: "",
        //   dropout_below_perc: "",
        //   dropout_rep_perc_per_batch: "",
        //   assessment_applicable: "",
        //   certification_applicable: "",
        //   emp_bill_claim_perc: "",
        // } as any,
        // percentageInfo: "",
      },

      // bill_data: [] as any,
      // temp_data: [] as any,
      // bill_data_final: [] as any,
    };
  },
  async created() {
      await this.getDistrict();
    await this.getCompany();
    await this.getType();
    this.emitter.on("course-updated-drawer", async (data) => {
      console.log(data.contact_person);
        this.address_details.sub_district = data.upazila;
      this.address_details.district = data.district;
      this.address_details.address = data.address;
       this.address_details.remarks = data.mapping_info?.note;
      this.address_details.contact_person = data.contact_person;
      this.address_details.land_mark = data.land_mark;
       this.address_details.id = data.id
      
    });
     await this.SubDistrict();
  },
  methods: {
    async getCourseDetails(id) {
      await ApiService.get("course/show/" + `${id}`)
        .then((response) => {
          this.load = true;
          (this.stipenchecked = "no"),
            (this.managementchecked = "no"),
            (this.employmentchecked = "no"),
            (this.reassesmentchecked = "no"),
            (this.totchecked = "no");

          if (response.data.data?.isStipendCourse == 1) {
            this.stipenchecked = "yes";
          }
          if (response.data.data?.isManagementCourse == 1) {
            this.managementchecked = "yes";
          }
          if (response.data.data?.isEmploymentEligible == 1) {
            this.employmentchecked = "yes";
          }
          if (response.data.data?.isReassessmentEnabled == 1) {
            this.reassesmentchecked = "yes";
          }
          if (response.data.data?.isTotCourse == 1) {
            this.totchecked = "yes";
          }
          this.update_status = response.data.data.update_status;
          this.formData = response.data.data;
          // await this.courseLog();
          this.load = false;
          // console.log();
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },

      removeImage(imgIndex) {
      this.address_details.images.splice(imgIndex, 1);
    },
    getImageUrl(image) {
      return URL.createObjectURL(image);
    },

    onFileChange(event) {
      const files = event.target.files;
      const images = this.address_details.images;

      if (files && files.length) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          images.push(file);
          console.log(images);
        }
      }
    },

     async getDistrict() {
      await ApiService.get('configurations/geo_district/list')
        .then((response) => {
          this.empDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getType() {
      await ApiService.get('address/type')
        .then((response) => {
          this.Types = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },


    async getCompany() {
      let company = VueCookieNext.getCookie('_company_id');
      if (company == 'null') {
        this.companyData = true;
        company = '';
        await ApiService.get('company/list?company=' + company)
          .then((response) => {
            this.companies = response.data.data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }

    },

    async getSubDistrict(id) {
      let district = id
      await ApiService.get(
        'configurations/geo_upazila/list?district=' + district
      )
        .then((response) => {
          this.subDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async SubDistrict() {
      let district = this.address_details.district;
      await ApiService.get(
        'configurations/geo_upazila/list?district=' + district
      )
        .then((response) => {
          this.subDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async courseLog(id) {
      this.load = true;
      await ApiService.get("course/edit/" + id)
        .then((response) => {
          this.load = false;
          if (this.update_status == 1) {
            this.load = true;
            (this.stipenchecked = "no"),
              (this.managementchecked = "no"),
              (this.employmentchecked = "no"),
              (this.reassesmentchecked = "no"),
              (this.totchecked = "no");
            if (response.data.data) {
              // this.load = false;
              // console.log(response.data.data);
              // this.load = false;
              if (
                response.data.data?.isStipendCourse == 1 ||
                response.data.data?.isStipendCourse == "1"
              ) {
                this.stipenchecked = "yes";
              }
              if (
                response.data.data?.isManagementCourse == 1 ||
                response.data.data?.isManagementCourse == "1"
              ) {
                this.managementchecked = "yes";
              }
              if (
                response.data.data?.isEmploymentEligible == 1 ||
                response.data.data?.isEmploymentEligible == "1"
              ) {
                this.employmentchecked = "yes";
              }
              if (
                response.data.data?.isReassessmentEnabled == 1 ||
                response.data.data?.isReassessmentEnabled == "1"
              ) {
                this.reassesmentchecked = "yes";
              }
              if (
                response.data.data?.isTotCourse == 1 ||
                response.data.data?.isTotCourse == "1"
              ) {
                this.totchecked = "yes";
              }

              this.formData = response.data.data;
            }
            this.load = false;
          }
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },

    isStipendCourseCheck(event) {
      if (event.target.checked) {
        this.formData.isStipendCourse = 1;
        console.log(this.formData.isStipendCourse);
      } else {
        this.formData.isStipendCourse = 0;
        console.log(this.formData.isStipendCourse);
      }
    },
    isManagementCourseCheck(event) {
      if (event.target.checked) {
        this.formData.isManagementCourse = 1;
      } else {
        this.formData.isManagementCourse = 0;
      }
    },
    isEmploymentCourseCheck(event) {
      if (event.target.checked) {
        this.formData.isEmploymentEligible = 1;
      } else {
        this.formData.isEmploymentEligible = 0;
      }
    },
    isReassessmentCheck(event) {
      if (event.target.checked) {
        this.formData.isReassessmentEnabled = 1;
      } else {
        this.formData.isReassessmentEnabled = 0;
      }
    },
    isToTCheck(event) {
      if (event.target.checked) {
        this.formData.isTotCourse = 1;
      } else {
        this.formData.isTotCourse = 0;
      }
    },

    totalcourseCost() {
      if (this.formData.total_target_trainee && this.formData.unit_cost_total) {
        let targetTrainee = +this.formData.total_target_trainee;
        let unitcost = +this.formData.unit_cost_total;
        this.formData.total_course_cost = targetTrainee * unitcost;
      }
    },
    async getContractPercentage(id) {
      await ApiService.get("entity/contract/show/" + id)
        .then((response) => {
          this.percentageInfo = response.data.data?.contribution_percentage;
          this.formData.min_age = response.data?.data?.min_age;
          this.formData.max_age = response.data?.data?.max_age;
          this.formData.age_grace_in_month =
            response.data?.data?.age_grace_in_month;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getSkillLevelList() {
      await ApiService.get('configurations/course_skill_level/list')
        .then((response) => {
          this.skillLevelList = response.data.data;
          console.log('skill level :');
          console.log(response.data.data);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche() {
      await ApiService.get("configurations/tranche/list")
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      await ApiService.get("entity/list")
        .then((response) => {
          this.association = response.data.data;
          console.log(response);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async gettrainning_instituteTypes() {
      await ApiService.get("configurations/course_type/list")
        .then((response) => {
          this.instituteTypes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getContactList() {
      await ApiService.get('entity/contract/list?tranche=' +
        this.formData.tranche +
        '&entity_info_id=' +
        this.formData.entity_id)
        .then((response) => {
           console.log(this.formData.entity_id);
          this.contactList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseTypeList() {
      await ApiService.get("configurations/course_type/list")
        .then((response) => {
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseAliasList() {
      await ApiService.get("configurations/alias_name/list")
        .then((response) => {
          this.courseAlias = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCategoryTypeList() {
      await ApiService.get("configurations/category_type/list")
        .then((response) => {
          this.categoryList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getSectorList() {
      await ApiService.get("configurations/sector_type/list")
        .then((response) => {
          this.sectorList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async formSubmit() {
      let formData = new FormData();
      formData.set('sub_district', this.address_details.sub_district);
       formData.set('district', this.address_details.district);
        formData.set('address',  this.address_details.address);
         formData.set('remarks', this.address_details.remarks);
          formData.set('contact_person',  this.address_details.contact_person);
           formData.set('land_mark', this.address_details.land_mark);
            formData.set('address_id',  this.address_details.id);
      let user_id = VueCookieNext.getCookie('_cpv_user');
      let role_id = VueCookieNext.getCookie('_cpv_role_id');
      formData.set('user_id', user_id.id);
      this.loading = true;
      await ApiService.post("address/update", formData)
        .then((response) => {
          if (response.status == 200) {
            DrawerComponent?.hideAll();

            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.emitter.emit("course-approve-update", true);
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Submission Error",
              html: response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
  setup() {
    const CourseInfoSchema = Yup.object().shape({});
    return {
      CourseInfoSchema,
    };
  },
});
